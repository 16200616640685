<template>
  <div style="margin:5px;" class="rowTitle">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 backBlack text-center" role="tab" @click="invsummary = !!invsummary">
        {{$parent.$parent.lang.inventory}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text> 
            <v-simple-table striped hover style="width:100%;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.item_code}}</th>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:40%">{{$parent.$parent.lang.description}}</th>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.qtty}}</th>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.item_price}}</th>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.total}}</th>
                    <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:86px;" v-if="showbuttoms"></th>
                  </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item,index) in items"
                    :key="index"
                >
                  <td>{{ item.itemid }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.total }}</td>
                  <td v-if="showbuttoms">
                    <!-- <i class="fas fa-pencil-alt ma-1" style="display:none;cursor:pointer;font-size:1.2rem;color:#383097" @click="EditLabor( item.id )"></i> -->
                    <!-- <i class="fas fa-trash ma-1" style="cursor:pointer;font-size:1.2rem;color:#383097" @click="DeleteLabor( item.id )"></i> -->
                    <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteLabor( item.id,index )">{{$parent.$parent.lang.delete}}</v-btn>
                  </td>
                </tr>
                <tr v-if="showbuttoms">
                  <td>
                    <b-input v-model="itemid" id="_id" @change="getProducts()" style="font-size: 0.8rem;width:100px"></b-input>
                  </td>
                  <td>
                    <b-input v-model="description" id="_description" style="font-size: 0.8rem"></b-input>
                  </td>
                  <td>
                    <b-input v-model="qty" id="_qty" @change="calckMe()" style="font-size: 0.8rem;width:50px"></b-input>
                  </td>
                  <td>
                    <b-input v-model="price" id="_price" @change="calckMe()" style="font-size: 0.8rem;width:50px"></b-input>
                  </td>
                  <td>
                    <b-input v-model="total" id="_total" readonly style="font-size: 0.8rem;width:70px"></b-input>
                  </td>
                  <td>
                    <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="EditLabor( 0 )">{{$parent.$parent.lang.add}}</v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    
  </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
  name: 'CVPartsTable',
  components: {},
  props: {
    ftotal: Number,
    totalPaid: Number
  },
  data: () => ({
    showbuttoms: true,
    items:[
      
    ],
    itemid: '',
    description: '',
    qty: '',
    price: '',
    total: '',
    cardid: '',
  }),
  computed:{
    iscancel: function(){
      return !this.$parent.showaddinv
    },
    canceled: function(){
      return !this.$parent.showaddinv
    }
  },
  created(){
    // this.cardid = this.$route.params.cardid;
    // this.getparts();
  },
  methods: {
    getProducts() {
        const post = new FormData();
        post.append("type","getProducts");
        post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
        post.append('data[item_number]',this.itemid);
        axios.post(
            this.$store.state.SAMCOTEC.r_path, post
        ).then((response) => {
            const NotStore = false;
            if(response.data.results.data.length > 0 || !NotStore){
                if(response.data.results.data[0].qty > 0 || !NotStore){
                  this.itemid = response.data.results.data[0].itemcode;
                  this.description = response.data.results.data[0].name;
                  this.price = response.data.results.data[0].price;
                  this.qty = 1;
                  this.calckMe();
                }else{
                  this.itemid = '';
                  this.description = '';
                  this.qty = '';
                  this.price = '';
                  this.total = '';
                  let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                  this.$snotify.error(message, 'تنبيه', {
                      timeout: 1000000,
                      showProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      position: SnotifyPosition.centerCenter,
                      buttons: [
                          {
                              text: this.$parent.$parent.lang.close, 
                              action: (toast) => {
                                  this.$snotify.remove(toast.id); 
                              } 
                          },
                      ]
                  });
                }
                
            }else{
              this.itemid = '';
              this.description = '';
              this.qty = '';
              this.price = '';
              this.total = '';
              let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
              this.$snotify.error(message, 'تنبيه', {
                  timeout: 1000000,
                  showProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  position: SnotifyPosition.centerCenter,
                  buttons: [
                      {
                          text: this.$parent.$parent.lang.close, 
                          action: (toast) => {
                              this.$snotify.remove(toast.id); 
                          } 
                      },
                  ]
              });
            }
            this.calckMe();
        })
    },
    calckMe(){
      this.total = parseFloat(this.qty) * parseFloat(this.price);
    },
    EditLabor(id){
      const formdata = new FormData();
      formdata.append('type','updateCItem');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','parts');
      if(id == 0){
        if(this.itemid == '' && this.$store.state.licenseType.require_itemcode){
          document.getElementById('_id').style.background = '#fbff00';
          document.getElementById('_id').focus();
          return false;
        }

        if(this.description == ''){
          document.getElementById('_description').style.background = '#fbff00';
          document.getElementById('_description').focus();
          return false;
        }
        
        if(this.qty == 0 || this.qty == ''){
          document.getElementById('_qty').style.background = '#fbff00';
          document.getElementById('_qty').focus();
          return false;
        }
        
        if(this.price == 0 || this.price == ''){
          document.getElementById('_price').style.background = '#fbff00';
          document.getElementById('_price').focus();
          return false;
        }

        formdata.append('data[itemid]',this.itemid);
        formdata.append('data[description]',this.description);
        formdata.append('data[qty]',this.qty);
        formdata.append('data[price]',this.price);
        formdata.append('data[total]',this.total);

        axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          // // console.log(res);
          this.price = 0;
          this.total = 0;
          this.description = '';
          this.getparts();
          this.itemid = '';
          this.description = '';
          this.qty = 1;
          this.price = 0;
          this.total = 0;
          this.$parent.$parent.getCard()
        })
        
      }
    },
    DeleteLabor(id,index){
      const def = this.ftotal - this.items[index].price;
      if( def < this.totalPaid ){
        
        this.$snotify.error(this.$parent.$parent.lang.you_cannot_delete_this_item_paid_more_than_total, 'تنبيه', {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                    text: this.$parent.$parent.lang.close, 
                    action: (toast) => {
                        this.$snotify.remove(toast.id); 
                    } 
                },
            ]
        });
        return false;
      }
      const ritem = this.items[index];
      const post = new FormData();
      post.append('type','deletedItem');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[id]',ritem.id);
      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
            this.getparts()
          }
        }
      )

    },
    getparts(){
      const formdata = new FormData();
      formdata.append('type','getCItem');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','parts');
       axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          this.items = res.results.data.results;
        });
    }
  }
}
</script>
<style>
.rowTitle{
  background:#FFF;
  color:#000;
  font-size:14px;
  text-align: right;
  padding:3px 3px 5px 3px;
}
thead th,tbody td{
  height: 30px !important;
}
</style>